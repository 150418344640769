import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, map } from 'rxjs';
import { IUser } from './user';
import { ReusableService } from 'src/app/services/reusable.service';
import { ApiEndpoint, IApiResponse } from '../http/api';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _user: BehaviorSubject<IUser | null> =
    new BehaviorSubject<IUser | null>(null);

  constructor(private _reusableService: ReusableService) {}

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: IUser) {
    // Store the value
    this._user.next(value);
  }

  get user$(): Observable<IUser | null> {
    return this._user.asObservable();
  }

  get userValue(): IUser | null {
    return this._user.value;
  }

  getProfile() {
    return this._reusableService.get(ApiEndpoint.Profile, {}).pipe(
      map(
        (
          res: IApiResponse<{
            fullName: string;
            email: string;
            phoneNumber: string;
            userName: string;
            avatarUrl: string;
          }>
        ) => {
          return res.result;
        }
      )
    );
  }
}
